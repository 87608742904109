import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86')
];

export const server_loads = [2,6,4,5];

export const dictionary = {
		"/(frontend)": [~8,[2]],
		"/(frontend)/archiv": [47,[2]],
		"/(frontend)/(content-routes)/detail/announcement/[id]": [~9,[2]],
		"/(frontend)/(content-routes)/detail/document/companyAgreement/[id]": [~10,[2]],
		"/(frontend)/(content-routes)/detail/document/correspondence/[id]": [~11,[2]],
		"/(frontend)/(content-routes)/detail/document/file/[id]": [~12,[2]],
		"/(frontend)/(content-routes)/detail/document/general/[id]": [~13,[2]],
		"/(frontend)/(content-routes)/detail/document/invitation/[id]": [~14,[2]],
		"/(frontend)/(content-routes)/detail/document/protocol/[id]": [~15,[2]],
		"/(frontend)/(content-routes)/detail/document/resolution/[id]": [~16,[2]],
		"/(frontend)/(content-routes)/detail/document/wageAgreement/[id]": [~17,[2]],
		"/(frontend)/(content-routes)/detail/event/[id]": [~18,[2]],
		"/(frontend)/(content-routes)/detail/notification": [~19,[2]],
		"/(frontend)/(content-routes)/detail/phoneCall/[id]": [~20,[2]],
		"/(frontend)/(content-routes)/detail/role/[id]": [~21,[2]],
		"/(frontend)/(content-routes)/detail/task/[id]": [~22,[2]],
		"/(frontend)/(content-routes)/detail/user/[id]": [~23,[2]],
		"/einrichtungsassistent": [~86,[7]],
		"/(frontend)/(content-routes)/forms/announcement/[[id]]": [~24,[2]],
		"/(frontend)/(content-routes)/forms/documentFile/[[id]]": [~32,[2]],
		"/(frontend)/(content-routes)/forms/document/companyAgreement/[[id]]": [~25,[2]],
		"/(frontend)/(content-routes)/forms/document/correspondence/[[id]]": [~26,[2]],
		"/(frontend)/(content-routes)/forms/document/general/[[id]]": [~27,[2]],
		"/(frontend)/(content-routes)/forms/document/invitation/[[id]]": [~28,[2]],
		"/(frontend)/(content-routes)/forms/document/protocol/[[id]]": [~29,[2]],
		"/(frontend)/(content-routes)/forms/document/resolution/[[id]]": [~30,[2]],
		"/(frontend)/(content-routes)/forms/document/wageAgreement/[[id]]": [~31,[2]],
		"/(frontend)/(content-routes)/forms/event/[[id]]": [~33,[2]],
		"/(frontend)/(content-routes)/forms/generalDocument/[[id]]": [~34,[2]],
		"/(frontend)/(content-routes)/forms/mandator": [~35,[2]],
		"/(frontend)/(content-routes)/forms/phoneCall/[[id]]": [~36,[2]],
		"/(frontend)/(content-routes)/forms/photoAdd/[id]": [~37,[2]],
		"/(frontend)/(content-routes)/forms/photoDocumentAdd": [~38,[2]],
		"/(frontend)/(content-routes)/forms/photoDocumentEdit/[id]": [~39,[2]],
		"/(frontend)/(content-routes)/forms/protocolDocument/[[id]]": [~40,[2]],
		"/(frontend)/(content-routes)/forms/role/[[id]]": [~41,[2]],
		"/(frontend)/(content-routes)/forms/search": [~42,[2]],
		"/(frontend)/(content-routes)/forms/task/[[id]]": [~43,[2]],
		"/(frontend)/(content-routes)/forms/team/[[id]]": [~44,[2]],
		"/(frontend)/(content-routes)/forms/user/settings": [~46,[2]],
		"/(frontend)/(content-routes)/forms/user/[[id]]": [~45,[2]],
		"/(login)/login": [~81,[6]],
		"/(login)/login/2fa": [~82,[6]],
		"/(login)/logout": [~83,[6]],
		"/(frontend)/meine-arbeit": [~48,[2,3]],
		"/(frontend)/meine-arbeit/aufgaben": [~49,[2,3]],
		"/(frontend)/meine-arbeit/dokumente": [~50,[2,3]],
		"/(frontend)/meine-arbeit/dokumente/allgemein": [~51,[2,3]],
		"/(frontend)/meine-arbeit/dokumente/einladungen": [~52,[2,3]],
		"/(frontend)/meine-arbeit/dokumente/protokolle": [~53,[2,3]],
		"/(frontend)/meine-arbeit/dokumente/schriftwechsel": [~54,[2,3]],
		"/(frontend)/meine-arbeit/telefonliste": [~55,[2,3]],
		"/(frontend)/meine-arbeit/termine": [~56,[2,3]],
		"/(login)/password-forgot": [~84,[6]],
		"/(login)/password-reset/[token]": [~85,[6]],
		"/(frontend)/profil": [~57,[2]],
		"/(frontend)/sekretariat": [~58,[2,4]],
		"/(frontend)/sekretariat/ankuendigungen": [~59,[2,4]],
		"/(frontend)/sekretariat/berichte": [60,[2,4]],
		"/(frontend)/sekretariat/einstellungen": [~61,[2,4]],
		"/(frontend)/sekretariat/funktionen": [~62,[2,4]],
		"/(frontend)/sekretariat/personen": [~63,[2,4]],
		"/(frontend)/sekretariat/teams": [~64,[2,4]],
		"/(frontend)/team/[id]": [~65,[2,5]],
		"/(frontend)/team/[id]/aufgaben": [~66,[2,5]],
		"/(frontend)/team/[id]/dokumente": [~67,[2,5]],
		"/(frontend)/team/[id]/dokumente/allgemein": [~68,[2,5]],
		"/(frontend)/team/[id]/dokumente/beschluesse": [~69,[2,5]],
		"/(frontend)/team/[id]/dokumente/betriebsvereinbarungen": [~70,[2,5]],
		"/(frontend)/team/[id]/dokumente/einladungen": [~71,[2,5]],
		"/(frontend)/team/[id]/dokumente/protokolle": [~72,[2,5]],
		"/(frontend)/team/[id]/dokumente/schriftwechsel": [~73,[2,5]],
		"/(frontend)/team/[id]/dokumente/tarifvertraege": [~74,[2,5]],
		"/(frontend)/team/[id]/fotos": [~75,[2,5]],
		"/(frontend)/team/[id]/fotos/[documentId]": [~76,[2,5]],
		"/(frontend)/team/[id]/mitglieder": [~77,[2,5]],
		"/(frontend)/team/[id]/sitzungen": [78,[2,5]],
		"/(frontend)/team/[id]/telefonliste": [~79,[2,5]],
		"/(frontend)/team/[id]/termine": [~80,[2,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';